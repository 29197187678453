import {getCurrentEbookFromLocalStorage} from "../tools/LocalStorage";
import {navigate} from "gatsby";
import {parsePublicUrlIntoPath} from "../components/Tools/parse";


export const DownloadInTab = (redirect = "/thank-you") => {
    const publicUrl = getCurrentEbookFromLocalStorage().document.publicURL
    const url = parsePublicUrlIntoPath(publicUrl);
    const a = document.createElement('a');
    a.href = url
    a.target = '_blank';
    a.click();

    navigate(redirect)
};

