import React from "react"
import { Helmet } from "react-helmet"


export default function BasicLayout({ children, SEOOptions , style}) {
  
  if (typeof window !== "undefined") {
    if(window!==undefined){
      window.addEventListener("scroll",
      function () {
        const navbarElt = document.getElementById("navbar")
        const navbarEltCtn = document.getElementById("navbar-content-ctn")
        if (window.scrollY>300) {
          navbarElt.classList.add("scrolled")
          navbarEltCtn.classList.add("scrolled-ctn")
        } else {
          navbarElt.classList.remove("scrolled")
          navbarEltCtn.classList.remove("scrolled-ctn")
        }
      }
      );    
    }
  }
  return (
    <div style={style}>
      <Helmet>
        <meta charSet="utf-8" />
        {SEOOptions.description !== undefined && <meta name="description" content={SEOOptions.description} />}
        <title>{SEOOptions.title}</title>
      </Helmet>
      {children}{" "}
    </div>
  )
}

