import React from "react"
import "../assets/styles/global.css"

import {graphql, navigate, useStaticQuery} from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/react"
// import Rgpd from "../components/Bar/rgpd"
import BasicLayout from "../layouts/BasicLayout"
import { SEO_OPTIONS_HANG_ON } from "../const/SEOOptions"
import {DownloadInTab} from "../services/EbookInTab";

const HangOnPage = (location) => {
  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "main-logo-blue.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      } 
      meeting: file(relativePath: { eq: "meeting.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            originalImg
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }      
    }
  `)

  return (
    <BasicLayout
    SEOOptions={SEO_OPTIONS_HANG_ON}

    >
    <div css={HangOnPageStyles}>
      <div className="header">
        <div className="logo">
          <Img
            fluid={data.logo.childImageSharp.fluid}
            alt="Focus Datascience logo"
            placeholderStyle={{ backgroundColor: `white` }}
          />
        </div>
        <h1 className="title-1">Hang on!</h1>
      </div>
      <div className="wrapper">
        <div className="one">

           <img style={{ width: "80%" , marginLeft:"11%" }} src={data.meeting.childImageSharp.fluid.originalImg}
            alt="FreeGuides"
           />

        </div>
        <div className="two">
          <h1 className="title-2">
            What will you do with
            <br /> <span className="allData">all of those datas?</span>
          </h1>
          <p className="accroche">
            A study is a long and complex journey.
            <br />
            <br />
            Talking with an expert can help you avoid{" "}
            <b>extra budget and anxiety</b> on the road and ensure your study
            will be serein as you imagine it.
          </p>
          Get every answers you need about:
          <div className="list">
            <ul>
              <li>Study Cycle</li>
              <li>Datas & statistics</li>
              <li>EDCs</li>
            </ul>
            <ul>
              <li>Roles, Responsabilities</li>
              <li>Management</li>
              <li>ICH & Norms</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="links">
        <button className={"hangonbtn"} onClick={() => navigate("/meeting/")}>

          DOWNLOAD THE EBOOK + <br /> BOOK A FREE CONSULTING SESSION WITH A DATA
          EXPERT

        </button>
        <button className={"hangonbtn"} onClick={() => DownloadInTab()}>
          {" "}
          I'M AN ADVANCED PROJECT MANAGER JUST SEND ME THE EBOOK
        </button>
      </div>
      <p className="proposition">
        This is an unique proposition,
        <br /> you won't find it elsewhere.
      </p>
      {/* <Rgpd location={location}/> */}
    </div>
    </BasicLayout>
  )
}

export default HangOnPage

const HangOnPageStyles = css`
  .header {
    text-align: center;
    
  }

  .logo {
    width: 11.52rem;
    display: block;
    margin: auto;
  }

  .title-1 {
    font-size: 50px;
    color: #133c9e;
  }

  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "image text";
    margin-top: 50px;

    justify-content: center;
    max-width: 1200px;
    display: grid;
    margin: auto;
  }

  .one {
    grid-area: image;
    margin: auto;
  }

  .two {
    grid-area: text;
    max-width: 400px;
    display: grid;
    grid-template-rows: 0.8fr 1fr;
    align-content: center;
  }

  h1.title-2{
    margin: 0 0 0.67em 0;
  }

  .title-2 {
    font-size: 30px;
    font-weight: 500;
  }

  .accroche {
    font-size: 22px;
    margin-top: 0;
  }

  .allData {
    border-bottom: 2px solid #133c9e;
  }

  .list {
    display: grid;
    grid-template-areas: "a a";
    text-align: left;
    padding: 0 5px;
  }

  .hangonbtn {
    text-decoration: none;
    height: 70px;
    width: 543px;
    text-align: left;
    padding-left: 15px;
    background-color: #133c9e;
    color: white;
    font-weight: 600;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    margin: auto;
    margin-top: 30px;
    text-align: center;
    display: inline-grid;
    align-content: center;
    &:focus {
      outline: none;
    }
  }

  .links {
    display: grid;
    margin: 18px auto 40px auto;
  }

  .hangonbtn:first-of-type {
    box-shadow: 5px 8px 8px rgb(0 0 0 / 20%);
  }

  .hangonbtn:last-of-type {
    color: #133c9e;
    background-color: white;
    border: 2px solid #133c9e;
  }

  .proposition {
    text-align: center;
    font-style: italic;
    font-weight: 300;
    margin-bottom: 80px;
  }

  @media screen and (max-width: 600px) {
    .wrapper {
      grid-template-columns: 1fr;
      grid-template-areas:
        "image"
        "text";
    }

    .title-2 {
      margin: auto;
    }

    .accroche {
      padding: 0 10px;
    }

    .two {
      text-align: center;
      margin: auto;
    }

    .list {
      margin: auto;
    }

    button {
      height: 90px;
      width: 243px;
    }
  }
`
