export const SEO_OPTIONS_FREE_GUIDES = {
  title: "Clinical Trial And Data Management Guides",
  description: "Download our free guides to understand the clinical trial environment and get a better picture on how to collect, manage and analyze reliable data."
}

export const SEO_OPTIONS_DM = {
  title: "Data Management | Focus DataScience",
  description: "Collect, manage and analyze reliable data from your Health Trial: Clinical Study & Real World Evidence."
}

export const SEO_OPTIONS_INDEX = {
  title: "Focus DataScience | Contract Research Organization",
  description: "Our CRO propose Next-Gen Trial Management for Clinical Studies Phase II-IV. Get faster and more reliable answers for your solution."
}

export const SEO_OPTIONS_ABOUT_US = {
  title: "About Our CRO | Focus DataScience",
  description: "We ease Lives of Decision Makers by Removing Doubt and Anxiety from Trial Management."
}

export const SEO_OPTIONS_BIOSTATISTICS = {
  title: "Biostatistics | Focus DataScience",
  description: "Get answers from your data. We analyze your data."
}

export const SEO_OPTIONS_DMBLUEPRINT = {
  title: "Data Management Blueprint | Focus DataScience",
  description: "Free Consulting Session with a Trial Expert: Get a free tailored blueprint to clearly understand how to collect, manage and analyze reliable data for your trial."
}

export const SEO_OPTIONS_HANG_ON = {
  title: "Focus DataScience - Hang-on",
  description: undefined
}

export const SEO_OPTIONS_MEETING = {
  title: "Meeting | Focus DataScience",
  description: "Meet an expert of clinical trial and get your answer regarding trial data management."
}

export const SEO_OPTIONS_MENTIONS = {
  title: "Legal Notices | Focus DataScience",
  description: undefined
}

export const SEO_OPTIONS_POLICY = {
  title: "Privacy Policy | Focus DataScience",
  description: "Read our privacy policy."
}

export const SEO_OPTIONS_QUALITY = {
  title: "Quality Assurance | Focus DataScience",
  description: "Ensure your trial and your data management are compliant with health authorities and aligned with good practices."
}

export const SEO_OPTIONS_REFERENCES = {
  title: "Our References & Testimonies | Focus DataScience",
  description: "Organisations that trust us."
}

export const SEO_OPTIONS_THANK_YOU = {
  title: "Focus DataScience - Thank-you",
  description: undefined
}

export const SEO_OPTIONS_CONTACT_US = {
  title: "Contact Us | Focus DataScience",
  description: "Claim a free consulting session with an expert. Drop us a line by email or call us."
}

export const SEO_OPTIONS_START_HERE = {
  title: "Start Here | Focus DataScience",
  description: "Don't know where to start? Here is a quick step by step guide to understand how to proceed."
}

export const SEO_OPTIONS_OVERSIGHT = {
    title:'Data Management Oversight | Focus DataScience',
    description:undefined
}